/* eslint-disable react/jsx-no-constructed-context-values */
import React from 'react';

import { useSelector } from 'react-redux';

import { useIntl } from 'ha/i18n';
import {
  getIsAdvertiser,
  getIsAuthenticated,
} from 'ha/modules/AuthLogic/selectors';

import { SmartTrack as Track } from 'ha/components/track/SmartTrack';

import { AdminImpersonationBanner } from './components/AdminImpersonationBanner/AdminImpersonationBanner';
import { LoadableAdvertiserQuickActions } from './components/AdvertiserQuickActions/AdvertiserQuickActions.lazy';
import { HeaderQuickActions } from './components/HeaderQuickActions/HeaderQuickActions';
import { LoggedOutQuickActions } from './components/LoggedOutQuickActions/LoggedOutQuickActions';
import { NavigationAppBar } from './components/NavigationAppBar/NavigationAppBar';
import {
  NavigationLogo,
  NavigationLogoProps,
} from './components/NavigationLogo/NavigationLogo';
import { LoadableTenantQuickActions } from './components/TenantQuickActions/TenantQuickActions.lazy';

export const DefaultLogo = ({ size }: Pick<NavigationLogoProps, 'size'>) => {
  const { urlResolver } = useIntl();

  const isAdvertiser = useSelector(getIsAdvertiser);

  return (
    <Track name="Clicked Logo in header" type="icon">
      <a
        href={urlResolver.getHomeUrl({ explicitNoRedirect: isAdvertiser })}
        data-test-locator="Navigation/LogoHomePageLink"
      >
        <NavigationLogo size={size} />
      </a>
    </Track>
  );
};

const DefaultQuickActions = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isAdvertiser = useSelector(getIsAdvertiser);

  if (isAuthenticated) {
    if (isAdvertiser) return <LoadableAdvertiserQuickActions />;
    return <LoadableTenantQuickActions />;
  }

  return <LoggedOutQuickActions />;
};

interface NavigationHeaderProps {
  sticky?: boolean;
  slots?: {
    appBar?: typeof NavigationAppBar;

    logo?: React.ReactNode;
    dynamicIsland?: React.ReactNode;
    quickActions?: React.ReactNode;

    above?: React.ReactNode;
    below?: React.ReactNode;
  };
}

export const NavigationHeader = ({
  sticky = false,
  slots = {},
}: NavigationHeaderProps) => {
  const { appBar: AppBar = NavigationAppBar } = slots;

  return (
    <React.Fragment>
      <AdminImpersonationBanner />
      <AppBar
        sticky={sticky}
        slots={{
          above: slots.above,
          below: slots.below,
          logo: slots.logo ?? <DefaultLogo />,
          dynamicIsland: slots.dynamicIsland,
          quickActions: slots.quickActions ?? (
            <HeaderQuickActions.Context.Provider
              value={{ hasSidebar: false, showCTA: true }}
            >
              <DefaultQuickActions />
            </HeaderQuickActions.Context.Provider>
          ),
        }}
      />
    </React.Fragment>
  );
};

NavigationHeader.DefaultLogo = DefaultLogo;
NavigationHeader.DefaultQuickActions = DefaultQuickActions;
